import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import moment from 'moment';
import {socketClient}  from '../index';


const initialState = {
  events: [],
  technicians: [],
  customers: [],
  selectedCustomer: null,
  selectedVehicle: null,
  event: null,
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
    },
    getTechnicians(state, action) {
      state.technicians = action.payload;
    },
    getCustomers(state, action) {

      const customers = [];
      if(action.payload && action.payload.length){
        for(let i = 0; i < action.payload.length; i++){
          customers.push({
            id: action.payload[i].id,
            label: `Name: ${action.payload[i].name} , Phone: ${action.payload[i].phone_number}`,
            vehicles: action.payload[i].vehicles,
          });
        }
  
        state.customers = customers;
      }

    },
    selectCustomer(state, action) {
      const { customerId, customers } = action.payload;
      const customer = customers.filter(customer => customer.id === customerId);
      state.selectedCustomer = customer[0];
    },
    selectVehicle(state, action) {
      const { vehicleId, vehicles } = action.payload;
      const vehicle = vehicles.filter(vehicle => vehicle.id === vehicleId);
      state.selectedVehicle = vehicle[0];
    },
    selectEvent(state, action) {
      const events = action.payload.events;
      const eventId = action.payload.eventId;

      for(let i = 0; i < events.length; i++){
        if(events[i].id === eventId){
          console.log(events[i]);
          state.event = events[i];
        }
      }
      console.log(eventId);
      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openDrawerPanel(state) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const requestGetRecentCustomers = createAsyncThunk('requestGetRecentCustomers', async function () {
  socketClient.emit('getCustomers', await firebase.auth().currentUser.getIdToken());
});

export const getCustomers = createAsyncThunk(
  'getCustomers',
  async function (_, { dispatch }) {
    await socketClient.on('getCustomers', (response) => {
      dispatch({ type: 'calendar/getCustomers', payload: response });
    });
  }
);

export const requestGetAllTechnicians = createAsyncThunk('requestGetAllTechnicians', async function () {
  socketClient.emit('getTechnicians', await firebase.auth().currentUser.getIdToken());
});

export const getTechnicians = createAsyncThunk(
  'getTechnicians',
  async function (_, { dispatch }) {
    await socketClient.on('getTechnicians', (response) => {
      dispatch(slice.actions.getTechnicians(response));
    });
});

export const requestGetAllEvents = createAsyncThunk('requestGetAllEvents', async function () {
  socketClient.emit('getAppointments', await firebase.auth().currentUser.getIdToken());
});

export const requestDeleteConcern = createAsyncThunk('requestDeleteConcern', async function (id) {
  socketClient.emit('deleteConcern', {idToken: await firebase.auth().currentUser.getIdToken(), id});
});

export const getEvents = createAsyncThunk(
  'getEvents',
  async function (_, { dispatch }) {
    await socketClient.on('getAppointments', (response) => {
      const events = [];
      for(let i = 0; i < response.length; i++){
        if(response[i].id === 10170){
          console.log(response[i]);
        }
        let allDay = moment(response[i].appointment_date).isBefore(response[i].appointment_date_end, 'day');
        events.push({
          id: response[i].id,
          color: response[i].technician.color,
          allDay,
          concerns: response[i].concerns.length ? [...response[i].concerns].sort((a, b) => a.id - b.id) : [],
          description: response[i].description,
          end: moment(response[i].appointment_date_end).isValid() ? moment(response[i].appointment_date_end).toDate().toISOString() : null,
          start: moment(response[i].appointment_date).isValid() ? moment(response[i].appointment_date).toDate().toISOString() : null,
          title: `${response[i].vehicle.year} ${response[i].vehicle.make} ${response[i].vehicle.model} - ${response[i].description}`,
          vehicle: response[i].vehicle,
          technician: response[i].technician,
          customer: response[i].vehicle.customer,
          send_reminder: response[i].send_reminder,
          dropoff: response[i].dropoff,
        });
      }
      dispatch(slice.actions.getEvents(events));
    });
});

export const createEvent = (data) => async () => {
  const dataPayload = {
    idToken: await firebase.auth().currentUser.getIdToken(),
    description: data.descriptions[0],
    vehicle_id: data.vehicle,
    customer_id: data.customer,
    date: data.start,
    date_end: data.end,
    technician: data.technician,
    concerns: data.concerns,
    send_reminder: data.send_reminder,
    dropoff: data.dropoff,
  };
  console.log(dataPayload);
  socketClient.emit('createAppointment', dataPayload);

};

export const selectEvent = (eventId, events) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId, events }));
};

export const selectCustomer = (customerId, customers) => async (dispatch) => {
  dispatch(slice.actions.selectCustomer({ customerId, customers }));
}

export const selectVehicle = (vehicleId, vehicles) => async (dispatch) => {
  dispatch(slice.actions.selectVehicle({ vehicleId, vehicles }));
}

export const searchForCustomer = (search) => async () => {
  socketClient.emit('searchForCustomer', {idToken: await firebase.auth().currentUser.getIdToken(), search});
};

export const updateEvent = (eventId, update) => async () => {
  console.log(update);
  socketClient.emit('updateAppointment', {idToken: await firebase.auth().currentUser.getIdToken(), eventId, update});

};

export const deleteEvent = (eventId) => async () => {
  
  socketClient.emit('deleteAppointment', {idToken: await firebase.auth().currentUser.getIdToken(), id: eventId});

};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openDrawerPanel = () => (dispatch) => {
  dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = () => (dispatch) => {
  dispatch(slice.actions.closeDrawerPanel());
};

export default slice;
