import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as dealershipReducer } from 'src/slices/dealership';
import { reducer as socketReducer } from 'src/slices/socket';
import { reducer as usersReducer } from 'src/slices/users';
import { reducer as invoicesReducer } from 'src/slices/invoices';
import { reducer as statsReducer } from 'src/slices/stats';
import { reducer as smsReducer } from 'src/slices/sms';
import { reducer as servicesReducer } from 'src/slices/services';
import { reducer as techniciansReducer } from 'src/slices/technicians';
import { reducer as payrollReducer } from 'src/slices/payroll';
import { reducer as serviceAdvisorsReducer } from 'src/slices/service_advisors';
import { reducer as settingsReducer } from 'src/slices/settings';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  dealership: dealershipReducer,
  socket: socketReducer,
  users: usersReducer,
  invoices: invoicesReducer,
  stats:  statsReducer,
  sms: smsReducer,
  services: servicesReducer,
  technicians: techniciansReducer,
  payroll: payrollReducer,
  serviceAdvisors: serviceAdvisorsReducer,
  settings: settingsReducer
});

export default rootReducer;
