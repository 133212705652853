import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchShopData } from 'src/slices/settings';
import useSubscriptionStatus from 'src/hooks/userSubscriptionStatus';

const RequireSubscription = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trialLeft, stripeCustomerId, loading } = useSubscriptionStatus();

  useEffect(() => {

    dispatch(fetchShopData());
    if (!stripeCustomerId && trialLeft <= 0 && loading === false) {
      navigate('/app/management/settings');
    }
  }, [stripeCustomerId, trialLeft, navigate]);

  return children;
};

export default RequireSubscription;