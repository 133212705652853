import moment from 'moment';
import firebase from 'src/utils/firebase';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { socketClient } from '../index';

const initialState = {
  loading: true,
  shop: {},
  stripeCustomerId: '',
  ownerId: '',
  url: '',
  systemMessage: '',
  trialLeft: '',
};

export const getShopData = createAsyncThunk('settings/getShopData', async () => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('getShopData', { idToken });
});

export const uploadLogo = createAsyncThunk('settings/uploadLogo', async (payload) => {
    const idToken = await firebase.auth().currentUser.getIdToken();
    const file = payload.file;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${file.name} ${moment().format('YYYYMMDDHHmmss')}`);
    await fileRef.put(file);
    const currentUrl = await fileRef.getDownloadURL();
    socketClient.emit('uploadLogo', { idToken, url: currentUrl });
});

export const shopInfoUpdate = createAsyncThunk('settings/shopInfoUpdate', async (payload) => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('shopInfoUpdate', { idToken, ...payload });
});

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShopData(state, action) {
      state.shop = action.payload;
      state.stripeCustomerId = action.payload.stripe_customer_id;
      state.ownerId = action.payload.owner_id;
      state.url = action.payload.shop_logo;
      state.loading = false;
    },
    setTrialLeft(state, action) {
      state.trialLeft = action.payload;
    },
    setSystemMessage(state, action) {
      state.systemMessage = action.payload;
    },
  },
});

export const { setShopData, setTrialLeft, setSystemMessage } = slice.actions;

export const fetchShopData = createAsyncThunk('settings/fetchShopData', async (_, { dispatch }) => {
  socketClient.on('getShopData', (data) => {
    dispatch(setShopData(data));
    const futureDate = moment(data.createdAt).add(30, 'days');
    const now = moment();
    const diff = futureDate.diff(now);
    const duration = moment.duration(diff);
    let timeLeft = duration.seconds() < 0 ? 'Sorry your trial period has expired, if you would like to continue using AutoClever please subscribe below.' : `${duration.days()} days, ${duration.hours()} hours, ${duration.minutes()} minutes, ${duration.seconds()} seconds until trial ends.`;
    dispatch(setTrialLeft(timeLeft));
  });
});

export const reducer = slice.reducer;