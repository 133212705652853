import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import {socketClient}  from '../index';


const initialState = {
  shopData: {},
  searchTerm: '',
  users: [],
  singleUser: {},
};

export const getFirebaseUser = createAsyncThunk('getFirebaseUser', async function () {
  return new Promise(async (resolve) => {
    const currentUser = await firebase.auth().currentUser;
    resolve(currentUser);
  });
}); 

export const setSearchTerm = createAsyncThunk('setSearchTerm', async function (searchTerm, { dispatch }) {
  dispatch({ type: 'users/setSearchTerm', payload: searchTerm });
});

export const requestGetAllUsers = createAsyncThunk('requestGetAllUsers', async function () {
  socketClient.emit('getCustomers', await firebase.auth().currentUser.getIdToken());
});

export const getSingleUser = createAsyncThunk('getSingleUser', async function (customerId) {
  socketClient.emit('updateCustomers', {idToken: await firebase.auth().currentUser.getIdToken(), customerId});
});

export const searchCustomer = createAsyncThunk('searchCustomer', async function (search, {dispatch}) {
  socketClient.emit('searchCustomer', {idToken: await firebase.auth().currentUser.getIdToken(), search});
  dispatch({ type: 'users/setSearchTerm', payload: search });
});

export const createCustomer = createAsyncThunk('createCustomer', async function (data) {
  socketClient.emit('createCustomer', {idToken: await firebase.auth().currentUser.getIdToken(), ...data});
});

export const editCustomerProfile = createAsyncThunk('editCustomerProfile', async function (data) {
  socketClient.emit('editCustomerProfile', {idToken: await firebase.auth().currentUser.getIdToken(), ...data});
});

export const createVehicle = createAsyncThunk('createVehicle', async function (data) {
  socketClient.emit('addVehicle', {...data , idToken: await firebase.auth().currentUser.getIdToken()});
});

export const createNewQuote = createAsyncThunk('createNewQuote', async function (data) {
  socketClient.emit('newQuote', {...data , idToken: await firebase.auth().currentUser.getIdToken()});
});

export const getShopData = createAsyncThunk('getShopData', async function () {
  socketClient.emit('getShopData', { idToken: await firebase.auth().currentUser.getIdToken() });
});

export const setVehicleForSale = createAsyncThunk('setVehicleForSale', async function ({vehicleId, customerId}, { dispatch }) {
  socketClient.emit('setVehicleForSale', { vehicleId, idToken: await firebase.auth().currentUser.getIdToken() });
  dispatch(getSingleUser(customerId));
});

export const fetchShopData = createAsyncThunk(
  'fetchShopData',
  async function (_, { dispatch }) {
    await socketClient.on('getCustomers', (response) => {
      dispatch({ type: 'users/fetchShopData', payload: response });
    });
});

export const fetchNewQuote = createAsyncThunk('fetchNewQuote', async function () {
  return new Promise((resolve) => {
    socketClient.on('newQuote', (response) => {
      resolve(response);
    });
  });
});
  
export const fetchAllUsers = createAsyncThunk(
  'fetchAllUsers',
  async function (_, { dispatch }) {
    await socketClient.on('getCustomers', (response) => {
      dispatch({ type: 'users/fetchAllUsers', payload: response });
    });
  });

export const fetchSingleUser = createAsyncThunk(
  'fetchSingleUser',
  async function (_, { dispatch }) {
    await socketClient.on('updateCustomers', (response) => {
      console.log(response);
      dispatch({ type: 'users/fetchSingleUser', payload: response });
    });
  });

export const fetchSearchUser = createAsyncThunk(
  'fetchSearchUser',
  async function (_, { dispatch }) {
    await socketClient.on('searchCustomer', (response) => {
      dispatch({ type: 'users/fetchAllUsers', payload: response });
    });
  });


export const fetchCreateCustomer = createAsyncThunk(
  'fetchCreateCustomer',
  async function (data, { dispatch }) {
    return new Promise(async (resolve) => {
      socketClient.emit('createCustomer', { idToken: await firebase.auth().currentUser.getIdToken(), ...data });
      socketClient.on('createCustomer', (response) => {
        dispatch({ type: 'users/fetchSingleUser', payload: response });
        resolve(response);
      });
    });
  }
);

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchShopData(state, action) {
      state.shopData = action.payload;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    fetchAllUsers(state, action) {
      state.users = action.payload;
    },
    fetchSingleUser(state, action) {
      state.singleUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetAllUsers.pending, (state) => {
      state.requestGetAllUsersStatus = 'Pending';
    });
    builder.addCase(requestGetAllUsers.fulfilled, (state) => {
      state.requestGetAllUsersStatus = 'Sent';
    });
    builder.addCase(requestGetAllUsers.rejected, (state) => {
      state.requestGetAllUsersStatus = 'Rejected';
    });
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.fetchAllUsersStatus = 'Payload Pending';
    });
    builder.addCase(fetchAllUsers.fulfilled, (state) => {
      state.fetchAllUsersStatus = 'Payload Fulfilled';
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.fetchAllUsersStatus = 'Payload Error';
    });
  },
});

export const reducer = slice.reducer;