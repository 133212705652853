import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import RequireSubscription from 'src/components/RequireSubscription';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const FileManager = Loader(
  lazy(() => import('src/content/applications/FileManager'))
);
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Calendar = Loader(
  lazy(() => import('src/content/applications/Calendar'))
);
const JobsPlatform = Loader(
  lazy(() => import('src/content/applications/JobsPlatform'))
);
const ProjectsBoard = Loader(
  lazy(() => import('src/content/applications/ProjectsBoard'))
);
const Mailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));

const applicationsRoutes = [
  {
    path: '/',
    element: <Navigate to="calendar" replace />
  },
  {
    path: 'calendar',
    element: (
      <RequireSubscription>
        <Calendar />
      </RequireSubscription>
    )
  },
  {
    path: 'file-manager',
    element: <FileManager />
  },
  {
    path: 'jobs-platform',
    element: <JobsPlatform />
  },
  {
    path: 'workflow',
    element: (
      <RequireSubscription>
        <ProjectsBoard />
      </RequireSubscription>
    )
  },
  {
    path: 'messenger',
    element: (
      <RequireSubscription>
        <Messenger />
      </RequireSubscription>
    )
  },
  {
    path: 'dealership',
    children: [
      {
        path: '/',
        element: (
          <RequireSubscription>
            <Navigate to="inbox" replace />
          </RequireSubscription>
        )
      },
      {
        path: 'tag/:labelTag',
        element: (
          <RequireSubscription>
            <Mailbox />
          </RequireSubscription>
        )
      },
      {
        path: 'tag/:labelTag/:mailboxCategory',
        element: (
          <RequireSubscription>
            <Mailbox />
          </RequireSubscription>
        )
      },
      {
        path: ':categoryTag',
        element: (
          <RequireSubscription>
            <Mailbox />
          </RequireSubscription>
        )
      },
      {
        path: ':categoryTag/:mailboxCategory',
        element: (
          <RequireSubscription>
            <Mailbox />
          </RequireSubscription>
        )
      }
    ]
  }
];

export default applicationsRoutes;
