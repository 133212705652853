import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import { socketClient } from '../index';

const initialState = {
  serviceAdvisors: [],
  loading: false,
  error: null,
};

export const fetchServiceAdvisors = createAsyncThunk('serviceAdvisors/fetchServiceAdvisors', async function () {
  socketClient.emit('getServiceAdvisors', await firebase.auth().currentUser.getIdToken());
});

export const getServiceAdvisors = createAsyncThunk(
  'getServiceAdvisors',
  async function (_, { dispatch }) {
    await socketClient.on('getServiceAdvisors', (response) => {
      console.log(response);
      dispatch(slice.actions.setServiceAdvisors(response));
    });
});

export const createServiceAdvisor = createAsyncThunk('serviceAdvisors/createServiceAdvisor', async function (payload) {
  socketClient.emit('createServiceAdvisor', { ...payload, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const editServiceAdvisor = createAsyncThunk('serviceAdvisors/editServiceAdvisor', async function (payload) {
  socketClient.emit('editServiceAdvisor', { ...payload, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const deleteServiceAdvisor = createAsyncThunk('serviceAdvisors/deleteServiceAdvisor', async function (id) {
  socketClient.emit('deleteServiceAdvisor', { id, idToken: await firebase.auth().currentUser.getIdToken() });
});

const slice = createSlice({
  name: 'serviceAdvisors',
  initialState,
  reducers: {
    setServiceAdvisors(state, action) {
      state.serviceAdvisors = action.payload;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceAdvisors.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServiceAdvisors.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchServiceAdvisors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setServiceAdvisors, setLoading, setError } = slice.actions;
export const reducer = slice.reducer;