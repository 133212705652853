import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getShopData, fetchShopData } from 'src/slices/settings';
import { getFirebaseUser } from 'src/slices/users';

import moment from 'moment';


const useSubscriptionStatus = () => {
  const dispatch = useDispatch();
  const [trialLeft, setTrialLeft] = useState('');
  const { shop, stripeCustomerId, loading } = useSelector((state) => state.settings);
  const [firebaseUser, setFirebaseUser] = useState(null);

  useEffect(() => {

    dispatch(getShopData());
    dispatch(fetchShopData());
    dispatch(getFirebaseUser()).then((response) => {
        setFirebaseUser(response.payload);
    });

    const updateCountdown = () => {
      if (shop) {
        const futureDate = moment(shop.createdAt).add(30, 'days');
        const now = moment();
        const diff = futureDate.diff(now);
        const duration = moment.duration(diff);
        let timeLeft = duration.seconds() < 0 ? 0 : 1;
        setTrialLeft(timeLeft);
      }
    };

    updateCountdown();

  }, []);

  return { trialLeft, shop, stripeCustomerId, loading, firebaseUser };
};

export default useSubscriptionStatus;